/**
 * @module insertMedia
 */
/// <reference path="custom.d.ts" />
import { findDOMNode } from "react-dom";
import { InsertMediaBase } from "./imagevault-insert-media-base";

/**
 * Extension method for jQuery that opens ImageVault and lets the user select one or multiple media assets.
 * 
 * To open ImageVault, select an iframe using jQuery and invoke the **imageVaultInsertMedia** function and supply it with a 
 * [configuration](../classes/insertMedia.imagevaultinsertmedia.config.html)
 * that atleast contains:
 * 
 * * [url to the ImageVault instance you would like to use](../classes/insertMedia.imagevaultinsertmedia.config.html#imageVaultUiUrl)
 * * [language](../classes/insertMedia.imagevaultinsertmedia.config.html#uiLang) that ImageVault should be displayed in
 * * [url of the site that the media should be used on](../classes/insertMedia.imagevaultinsertmedia.config.html#publishingSource)
 * * [callback function ](../classes/insertMedia.imagevaultinsertmedia.config.html#success) called when the user selects an asset in ImageVault
 *
 * If you would like a specific format, specify it by suppliying a 
 * [formatId](../classes/insertMedia.imagevaultinsertmedia.config.html#formatId).
 * If not, the user will be prompted with a popup where she can select from the available user formats.
 * 
 * If media should be edited (cropped or resized) before insert, then specify
 * [insertMode](../classes/insertMedia.imagevaultinsertmedia.config.html#insertMode) = 1.
 * 
 * To edit existing media in an editor, use
 * [mediaUrl](../classes/insertMedia.imagevaultinsertmedia.config.html#mediaUrl) and set it to the url of the media to edit.
 * This will open the editor when ImageVault opens.
 *
 * If you would like to get public urls (that don't need authentication), supply [publishDetails.text](../classes/insertMedia.imagevaultinsertmedia.publishdetails.html#text) and/or [publishDetails.url](../classes/insertMedia.imagevaultinsertmedia.publishdetails.html#url) and enter where the media is to be used.
 * 
 * The [close function](../classes/insertMedia.imagevaultinsertmedia.config.html#close)
 * can also come in handy if you run the script in a popup to know when to close it.
 * 
 * The [success](../classes/insertMedia.imagevaultinsertmedia.config.html#success) function is called with a
 * [InsertSuccessMessageEvent](../interfaces/insertMedia.imagevaultinsertmedia.insertsuccessmessageevent.html)
 * which has a [response](../interfaces/insertMedia.imagevaultinsertmedia.insertsuccessmessageevent.html#response) property
 * that contains a [InsertResponse](../classes/insertMedia.imagevaultinsertmedia.insertresponse.html)
 * that describes the media that the user selected.
 * 
 * ### Example:
 * 
 *     <script type="text/javascript" src="/scripts/jquery-3.1.1.js"></script>
 *     <script type="text/javascript" src="/scripts/imagevault-insert-media/scripts/insertmedia.min.js"></script>
 * 
 *     <script type="text/javascript">
 *        $("#insertMedia").imageVaultInsertMedia({
 *            imageVaultUiUrl:"https://myiv.com",
 *            uiLang:"en",
 *            publishingSource:"https://mysite.com",
 *            success:function(result){
 *              alert(result.response.Id);
 *            }
 *        });
 *     </script>
 *     <iframe id="insertMedia"></iframe>
 * 
 * You can <a href="/insertmedia" target="_top">test</a> the different config parameters to test the function.
 */

export module ImageVaultInsertMedia {
    /**
     * Callback used to receive the result from the success method
     */
    export interface IInsertSuccessCallback { (message: InsertSuccessMessageEvent): void };

    /**
     * Message returned from the Success Callback method
     */
    export interface InsertSuccessMessageEvent extends MessageEvent {
        /**
         * The response from the ImageVault insert operation
         */
        data: string | undefined;
        /**
         * The response from the ImageVault insert operation
         */
        response: InsertResponse | undefined;
    }
    /**
     * The response from ImageVault when inserting an asset
     */
    export class InsertResponse {
        /**
         * The media item id of the asset 
         */
        Id: Number | undefined;
        /**
         * The id of the vault where the asset resides
         */
        VaultId: Number | undefined;
        /**
         * The name of the asset
         */
        Name: string | undefined;
        /**
         * The conversion selected by the user. Is an array but will only contain one object
         */
        MediaConversions: MediaConversion[] | undefined;
        /**
         * Date when the asset was added to ImageVault
         */
        DateAdded: string | undefined;
        /**
         * Name of the user that added the asset to ImageVault
         */
        AddedBy: string | undefined;

    }

    /**
     * Defines a media asset, original or conversion
     */
    export class MediaConversion {
        /**
         * The url to the conversion
         */
        Url: string | undefined;
        /**
         * Name of the conversion
         */
        Name: string | undefined;
        /**
         * Html representing the conversion
         */
        Html: string | undefined;
        /**
         * Content type of the conversion
         */
        ContentType: string | undefined;
        /**
         * Width, in pixels, of the conversion
         */
        Width: number | undefined;
        /**
         * Height, in pixels, of the conversion
         */
        Height: number | undefined;
        /**
         * Aspect ratio of the conversion
         */
        AspectRatio: number | undefined;
        /**
         * Width of the selected/requested format
         */
        FormatWidth: number | undefined;
        /**
         * Height of the selected/requested format
         */
        FormatHeight: number | undefined;
        /**
         * Aspect ratio of the selected/requested format
         */
        FormatAspectRatio: number | undefined;
        /**
         * Name of the media format
         */
        MediaFormatName: string | undefined;
        /**
         * Id of the selected media format
         */
        MediaFormatId: number | undefined;
    }
    /**
     * Defines where an ImageVault asset is used when requesting it
     */
    export class PublishDetails {
        /**
        * The textual description on where an asset is used
        */
        text: string | undefined;
        /**
        * The url to where the asset is used
        */
        url: string | undefined;
        /**
        * An optional id for grouping usage
        */
        groupId: string | undefined;
    }

    /** 
      * Defines the configuration needed to invoke the insert function
      */
    export class Config {
        /**
         * The url to the ImageVault ui that should be used
         */
        imageVaultUiUrl: string | undefined;
        /**
         * [Optional] Origin where the insert function is launched from. Is normally calculated and does not need to be supplied.
         */
        origin: string | undefined;
        /**
         * The language that the ImageVault ui should be displayed in
         */
        uiLang: string | undefined;
        /**
         * [Optional] The language for the default content in ImageVault
         */
        pageLang: string | undefined;
        /**
         * The publishingSource where the image should be used. Normally the url for the site.
         */
        publishingSource: string | undefined;
        /**
         * If it should be possible to select multiple assets from ImageVault. Default is false.
         */
        insertMultiple: boolean | undefined;
        /**
         * The url base that the media assets should use. Supply the url to a cdn.
         */
        mediaUrlBase: string | undefined;
        /**
         * The ids of the formats that the selection should result in. 
         */
        formatId: string | undefined;
        /**
         * [Optional] The comma-separated id-list of additional metadata definitions that the selection should result in. 
         */
        additionalMetadataIds: string | undefined;
        /**
         * The publishDetails to use. If supplied, published urls are returned.
         */
        publishDetails: PublishDetails | undefined;
        /**
         * Function that is invoked when the user insert items from ImageVault
         */
        success: IInsertSuccessCallback | undefined;
        /**
         * This function is called when the Insert window should be closed
         */
        close: Function | undefined;
        /**
       * [Optional] This function is called whenever an error is encountered
       */
        error: Function | undefined;
        /**
         * [Optional] Listen on this method for debug messages
         */
        debug: Function | undefined;

        /**
         * [Optional] Set media url to edit existing media
         */
        mediaUrl: string | undefined;
        /**
         * [Optional] Set media id to show specific media
         */
        mediaId: number | undefined;

        /**
         * [Optional]
         * @0 (Default) Insert with format or choose format from dropdown in ImageVault UI
         * @1 Same as 0 (default) except that you can edit media in editor before insert
         */
        insertMode: number = 0; 
    }
    export class InsertMedia extends InsertMediaBase {
        iframe: HTMLIFrameElement | undefined;
        constructor(frame: HTMLIFrameElement, config: Config) {
            super(config);

            this.iframe = frame;
            if (!this.iframe) {
                throw "No valid iframe!!";
            }
            this.containerWindow = this.iframe.contentWindow as Window;

            var self = this;

        //    window.addEventListener("DOMContentLoaded", () => {
                // Listen for postMessage messages
                let eventWrapper = (evt: any) => self.messageEvent(evt);

                if (window.addEventListener) {
                    window.addEventListener("message", eventWrapper, false);
                } else if (window.attachEvent) {
                    window.attachEvent("message", (message: any) => { self.messageReceiver(message); });
                } else {
                    throw "Cannot attachEvent/addEventListener, not supported by browser";
                }
                self.setupCallback();
                self.setupComplete = true;

                // Remove event listeners when iframe is removed
                document.body.addEventListener("DOMNodeRemoved", function (evt) {
                    var removedNode = evt.target;
                    if (removedNode == self.iframe && window.removeEventListener) {
                        window.removeEventListener("message", eventWrapper, false);
                        window.removeEventListener("message", eventWrapper, true);

                    }
                }, false);
        //    });
        }
        openImageVault(): void {
            const config = this.config;

            if (!config) {
                throw "Config is missing!";
            }

            //formatId must be provided to clear format in IV<5.5
            if (!config.formatId) {
                config.formatId = "0";
            }

            let params = `mediaurlbase=${encodeURIComponent(config.mediaUrlBase as string)}&ensurepublishingsource=${encodeURIComponent(config.publishingSource as string)}` +
                (config.uiLang ? `&uilang=${encodeURIComponent(config.uiLang)}` : "") +
                (config.pageLang ? `&pagelang=${encodeURIComponent(config.pageLang)}` : "") +
                (config.mediaUrl ? `&mediaUrl=${encodeURIComponent(config.mediaUrl)}` : "") +
                (config.insertMode ? `&insertMode=${encodeURIComponent(config.insertMode.toString())}` : "") +
                (config.insertMultiple ? `&insertmultiple=${encodeURIComponent(config.insertMultiple.toString())}` : "") +
                `&formatId=${config.formatId.toString()}` +
                (config.additionalMetadataIds ? `&additionalMetadataIds=${encodeURIComponent(config.additionalMetadataIds.toString())}` : "");

            const publishDetails = config.publishDetails;
            if (publishDetails) {
                params += (publishDetails.text ? `&publishdetails.Text=${encodeURIComponent(publishDetails.text)}` : "") +
                    (publishDetails.url ? `&publishdetails.Url=${encodeURIComponent(publishDetails.url)}` : "") +
                    (publishDetails.groupId ? `&publishdetails.GroupId=${encodeURIComponent(publishDetails.groupId)}` : "");
            }
            params += config.mediaId ? `#search=${config.mediaId}` + "&items=" + config.mediaId : "#";

            // Set src of iframe 
            if (this.iframe)
                this.iframe.src = this.config?.imageVaultUiUrl + "?" + params;

            if (this.setupComplete) {
                this.postMessageCallbackReceived = false;
                this.setupCallback();
            }
        }
    }

}

// $.fn.extend({
//     imageVaultInsertMedia(config: ImageVaultInsertMedia.Config) {

//         const e = this.get(0);
//         if (e.imageVaultInsertMedia) {
//             e.imageVaultInsertMedia.readConfig(config);
//         } else {
//             e.imageVaultInsertMedia = new ImageVaultInsertMedia.InsertMedia(this, config);
//         }
//         e.imageVaultInsertMedia.openImageVault();

//         return true;
//     }
// });

