import { setup } from '@contentful/dam-app-base';
import { createClient } from 'contentful-management'
import logo from './logo.svg';
import * as ivlib from './lib/imagevault-insert-media';

const insertMediaLib = ivlib.ImageVaultInsertMedia;
const CTA = 'Insert media from ImageVault';

//
//  prepareAsset - Selects which conversion to use as published media
//  and which to use as thumbnail
//
function prepareAsset(resource, config) {
  var defaultId = parseInt(config.defaultFormatId, 10);
  var thumbId = parseInt(config.thumbnailFormatId, 10);

  var convDefault = resource.MediaConversions.filter(cnv => cnv.MediaFormatId === defaultId)[0];
  var convThumb = resource.MediaConversions.filter(cnv => cnv.MediaFormatId === thumbId)[0];
  var convEditedList = resource.MediaConversions.filter(cnv => cnv.MediaFormatId != 1 && cnv.MediaFormatId != defaultId && cnv.MediaFormatId != thumbId);

  var mediaUrl = convDefault.Url;
  var thumbUrl = convDefault.Url;
  var alt = convDefault.Name;
  var width  = convDefault.Width;

  if (convEditedList.length > 0) {
    mediaUrl = convEditedList[0].Url;
    thumbUrl = convEditedList[0].Url;
    width = convEditedList[0].Width;
  }

  if (width > 1024) {
    thumbUrl = convThumb.Url;
  }

  resource.mediaUrl = mediaUrl;
  resource.thumbUrl = thumbUrl;
  resource.altText = alt;

  return resource;
}

//
// makeThumbnail - Deliver the url and alttext for this asset
//
function makeThumbnail(resource, config) {
  return [resource.thumbUrl, resource.altText];
}

//
//  renderDialog - Open ImageVault inside the dialog
//
async function renderDialog(sdk) {
  const params = sdk.parameters.invocation;
  const cma = createClient(
    { apiAdapter: sdk.cmaAdapter },
  )
  const space = await cma.getSpace(sdk.ids.space)
  const environment = await space.getEnvironment(sdk.ids.environment)
  const entry = await environment.getEntry(sdk.ids.entry)
  

  var entryName = "Undefined"
  var field = entry.fields[params.contentType.displayField];
  if (field) {
    // Use the first existing field value.
    // This probably not a problem, even for multi languages scenarios, since
    // this name is only used in the PublishDetails
    entryName = field[Object.keys(field)[0]]; 
  }

  var frame = document.createElement("IFRAME");
  frame.setAttribute("style", "width: 100%; border: 0; top: 4px");
  document.body.appendChild(frame);

  window.onresize = function(evt) {
    if (frame) frame.style.setProperty("height", (window.innerHeight - 4).toString() + "px");
  }

  var options = new insertMediaLib.Config();
  options.imageVaultUiUrl = params.imagevaultUrl;
  options.mediaUrlBase = params.imagevaultUrl;
  options.origin = params.imagevaultUrl.replace(/\/$/, '');
  options.insertMode = 1;
  options.publishingSource = "https://app.contentful.com/";
  options.pageLang = "sv";
  options.uiLang = "sv";
  options.insertMultiple = true;
  options.formatId = params.defaultFormatId.toString() + "," + params.thumbnailFormatId.toString();
  options.success = function(evt) {
     sdk.close(evt.response);
  };
  options.publishDetails = new insertMediaLib.PublishDetails();
  options.publishDetails.groupId = `${space.name}|${environment.name}|${sdk.ids.entry}`;
  options.publishDetails.url = `https://app.contentful.com/spaces/${sdk.ids.space}/environments/${sdk.ids.environment}/entries/${sdk.ids.entry}`;
  options.publishDetails.text = `${entryName} - ${params.contentType.name}`;

  var im = new insertMediaLib.InsertMedia(frame, options);
  im.openImageVault();

  sdk.window.updateHeight(window.outerHeight);
}

//
//  openDialog - Launch the dialog and wait for the data from Imagevault.
//  Process- and return the data 
async function openDialog(sdk, currentValue, config) {

  var data = await sdk.dialogs.openCurrentApp({
    position: 'center',
    title: CTA,
    shouldCloseOnOverlayClick: true,
    shouldCloseOnEscapePress: true,
    parameters: { contentType: sdk.contentType, ...config },
    width: 1400,
  });

  var result = [];
  if (data) {
    if(Array.isArray(data)) {
      data.forEach(function (itm, idx) {
        result.push(pruneObject(itm, VALUE_TEMPLATE));
      });
    }
    else{
      result.push(pruneObject(data, VALUE_TEMPLATE));
    }
  }

  result.forEach(function (itm, idx) {
    result[idx] = prepareAsset(itm, config);
  });

  return result;
}


//
// Template object, describing which members to keep/strip when pruning the data
//
const VALUE_TEMPLATE = {
  Id: true,
  Name: true,
  Metadata: true,
  Categories: true,
  MediaConversions: {
    Id: true,
    Html: false,
    Url: true,
    Name: true,
    Width: true,
    Height: true,
    AspectRatio: false,
    ContentType: true,
    FormatWidth: true,
    FormatHeight: true,
    MediaFormatId: true
  }
};

//
// pruneObject - Remove undesired members from an object
//
function pruneObject(ob, templ) {
  var arr = [];
  for(var prop in templ) { arr.push(prop); }

  for(var prop in ob) {
    var tp = typeof templ[prop];
    if (!arr.includes(prop) || (tp === "boolean" && templ[prop] === false)) {
      delete ob[prop];
    }
    else if (tp === "object") {
      if (Array.isArray(ob[prop])) {
        ob[prop].forEach(function (itm, idx) {
          ob[prop][idx] = pruneObject(itm, templ[prop]);
        });
      }
      else {
        ob[prop] = pruneObject(ob[prop], templ[prop]);
      }
    }
  }
  return ob;
}

//
// validateParameters
//
function validateParameters(parameters) {
  if (!/^(?:(?:(?:https):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(parameters.imagevaultUrl)) {
    return 'Provide your full ImageVault URL.';
  }
  return null;
}

// Set up this property type
setup({
  cta: CTA,
  name: 'ImageVault',
  logo,
  description:
    'The ImageVault app allows editors to insert- and edit media from their ImageVault instance.',
  color: '#00acd7',
  parameterDefinitions: [
    {
      id: 'imagevaultUrl',
      name: 'ImageVault URL',
      description: 'The ImageVault instance that the app will connect to.',
      type: 'Symbol',
      required: true,
    },
    {
      id: 'defaultFormatId',
      name: 'Default format ID',
      description: 'The default ImageVault format id to use.',
      type: 'Integer',
      required: true,
      default: -1,
    },
    {
      id: 'thumbnailFormatId',
      name: 'Thumbnail format ID',
      description: 'The ImageVault format id to use as thumbnail representation.',
      type: 'Integer',
      required: true,
      default: -1,
    },
    // {
    //   id: 'format',
    //   name: 'Format',
    //   description:
    //     "The format of the assets. By setting it to 'websafe', ImageVault will decide on the most optimized format for your users. If you wish to keep the original format, set it to 'original'.",
    //   type: 'List',
    //   value:
    //     'original,websafe,gif,webp,jpg,png,tif,tiff',
    //   required: true,
    //   default: 'websafe',
    // },
  ],
  makeThumbnail: asset => [asset.thumbUrl, asset.altText],
  renderDialog,
  openDialog,
  isDisabled: () => false,
  validateParameters,
});
