import { ImageVaultInsertMedia } from "./imagevault-insert-media";


export abstract class InsertMediaBase {
    config: ImageVaultInsertMedia.Config;
    postMessageCallbackReceived: boolean;
    pingOn: boolean;
    containerWindow: Window;
    setupComplete: boolean | undefined;
    beforeClose: Function | undefined;
    constructor(config: ImageVaultInsertMedia.Config) {

        this.postMessageCallbackReceived = false;
        this.pingOn = false;
        this.config = config;
        this.readConfig(config);
        this.containerWindow = window;
    }
    messageEvent(message: any) {
        this.messageReceiver(message);
    }
    readConfig(config: ImageVaultInsertMedia.Config): void {
        let errorMessage = null;
        if (config.origin == null) {
            const a = document.createElement("a");
            a.href = config.imageVaultUiUrl as string;
            config.origin = a.protocol + "//" + a.hostname;
            //if port is specified and differs from default port, add it
            if (a.port && ((/http:/i.test(a.protocol) && a.port !== "80") || (/https:/i.test(a.protocol) && a.port !== "443")))
                config.origin += `:${a.port}`;
        }
        if (!config.publishingSource) {
            errorMessage = "Publish source must be configured";
        }

        if (errorMessage != null && config.error)
            config.error(null, errorMessage);
        else if (errorMessage != null)
            throw (errorMessage);
        this.config = config;
    }
    abstract openImageVault(): void;

    // Initiate the postMessage contact until response is received
    setupCallback() {
        var self = this;
        if (this.postMessageCallbackReceived)
            return true;

        if (this.containerWindow.postMessage) {
            try {
                this.containerWindow.postMessage("init", this.config.origin as string);
            } catch (e) { }
            setTimeout(() => { self.setupCallback(); }, 1000);
        }
        return false;
    }

    // Ping origin
    ping() {
        var self = this;
        if (!this.pingOn) return;
        if (this.containerWindow.postMessage) {
            try {
                this.containerWindow.postMessage("ping", this.config.origin as string);
            } catch (e) { }
        }
        setTimeout(() => { self.ping(); }, 1000);
    }

    // Receive message receiver
    messageReceiver(message: any) {
        var self = this;

        // Ignore messages not for us.
        if (message.origin !== this.config.origin) {
            if (this.config.error)
                this.config.error(message, "origin does not match");

            return;
        }

        // Notify setupCallback that communication has been established
        if (message.data === "initReceived") {
            this.postMessageCallbackReceived = true;
            this.pingOn = true;
            setTimeout(() => { self.ping(); }, 1000);

            if (this.config.debug)
                this.config.debug(message);
            return;
        }

        // Ping response, ignore
        if (message.data === "pong") {
            if (this.config.debug)
                this.config.debug(message);
            return;
        }

        // Indicates that we can close the window
        if (message.data === "close") {
            this.pingOn = false;
            if (this.beforeClose)
                this.beforeClose(message);
            if (this.config.close)
                this.config.close(message);

            return;
        }

        // All other messages are insert messages
        if (this.config.success) {
            message.response = JSON.parse(message.data);
            this.config.success(message);
        }
    }
}